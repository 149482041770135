@forward 'libs/shared/rx-components/styles/variables';

@use 'libs/shared/rx-components/styles/variables' as RV;

// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

//Borders
$border-color: var(--color-grey-light);
$border-radius: 0.25rem;

// Fonts
$font-family-default: var(--font-regular), 'Helvetica Neue', Helvetica, Arial, sans-serif;
