@import 'mixins';
@import 'variables';

body {
  @include font-smoothing-antialiased;
}

p {
  color: var(--color-grey-darker);
}

a {
  color: var(--color-primary-dark);
  &:not(.nav-link) {
    text-decoration: underline;
  }

  &:hover {
    text-decoration: underline;
    color: var(--color-primary-darker);
  }

  &:focus {
    outline: none;
    color: var(--color-primary-dark);
    text-decoration: underline;
  }
}
