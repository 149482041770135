@use '../variables' as *;

@mixin corpaylodging-theme-variables {
  --color-primary-darker: #{$color-corpay-red-main-darker};
  --color-primary-dark: #{$color-corpay-red-main-dark};
  --color-primary: #{$color-corpay-red-main};
  --color-primary-light: #{$color-corpay-red-main-light};
  --color-primary-lighter: #{$color-corpay-red-main-lighter};

  --color-orange: #{$color-corpay-orange};

  --color-grey-darker: #{$color-corpay-grey-darker};
  --color-grey-dark: #{$color-corpay-grey-dark};
  --color-grey: #{$color-corpay-grey};
  --color-grey-light: #{$color-corpay-grey-light};
  --color-grey-lighter: #{$color-corpay-grey-lighter};

  --color-green-darker: #{$color-corpay-green-darker};
  --color-green-dark: #{$color-corpay-green-dark};
  --color-green: #{$color-corpay-green};
  --color-green-light: #{$color-corpay-green-light};
  --color-green-lighter: #{$color-corpay-green-lighter};

  --color-red-darker: #{$color-corpay-red-darker};
  --color-red-dark: #{$color-corpay-red-dark};
  --color-red: #{$color-corpay-red};
  --color-red-light: #{$color-corpay-red-light};
  --color-red-lighter: #{$color-corpay-red-lighter};

  --color-yellow-darker: #{$color-corpay-yellow-darker};
  --color-yellow-dark: #{$color-corpay-yellow-dark};
  --color-yellow: #{$color-corpay-yellow};
  --color-yellow-light: #{$color-corpay-yellow-light};
  --color-yellow-lighter: #{$color-corpay-yellow-lighter};

  --color-plum-darker: #{$color-corpay-plum-darker};
  --color-plum-dark: #{$color-corpay-plum-dark};
  --color-plum: #{$color-corpay-plum};
  --color-plum-light: #{$color-corpay-plum-light};
  --color-plum-lighter: #{$color-corpay-plum-lighter};

  --color-blue-darker: #{$color-corpay-blue-darker};
  --color-blue-dark: #{$color-corpay-blue-dark};
  --color-blue: #{$color-corpay-blue};
  --color-blue-light: #{$color-corpay-blue-light};
  --color-blue-lighter: #{$color-corpay-blue-lighter};

  --font-regular: #{$font-corpay-regular};
  --font-semibold: #{$font-corpay-semibold};
  --font-bold: #{$font-corpay-semibold};

  --button-background-hover: #{$color-corpay-red-main};
  --input-active-stroke: #{$color-corpay-grey-dark};


}
