// -----------------------------------------------------------------------------
// A shame file is one where you put all the hacks you’re not proud of so that
// they don’t get lost and ignored. Inspired by https://csswizardry.com/2013/04/shame-css-full-net-interview
// -----------------------------------------------------------------------------

@use 'abstracts' as *;

body {
  &.mobile {
    main {
      margin-bottom: 7rem !important;
    }
  }

  &.noscroll {
    overflow: hidden;
  }
}

.table .pi {
  font-family: 'primeicons';
}

.map-modal {
  .modal-dialog {
    max-width: 100%;
    height: 100%;
    padding: 5%;
    margin-top: 0;
    margin-bottom: 0;

    .modal-content {
      height: 100%;
      width: 100%;

      /* Override plugin default styles */
      .gm-style {
        padding: 1rem;

        /* Add transition to infoboxes when appearing on map */
        .gm-style-iw-t {
          &::after,
          .gm-style-iw-c {
            @include fade-in(300ms);
          }
          .gm-style-iw-c:has(rx-hotel-card) {
            @include media-breakpoint-up(md) {
              width: auto;
              padding: 0 !important;
              min-height: 0 !important;
              height: auto;
              max-height: 12.125rem !important;
              min-width: unset !important;
            }
            width: 12.5rem;
            height: fit-content;
            min-height: 20.313rem;
            max-height: 23.5rem !important;
            padding: 0 !important;
            min-width: 12.5rem !important;
          }
        }
      }

      div.gm-style-iw-chr {
        display: none;
      }

      /* Hide the close button */
      .gm-style-iw > button {
        display: none !important;
      }

      .gm-style .gm-style-iw {
        padding: 0;

        &:has(rx-hotel-card) {
          @include media-breakpoint-up(md) {
            width: auto;
          }
          width: 12.5rem;
        }

        .gm-style-iw-d {
          max-width: 100% !important;
          max-height: 100% !important;

          @include media-breakpoint-up(md) {
            overflow: hidden !important;
          }
        }

        .gm-style-iw-d:has(rx-hotel-card) {
          max-width: 100% !important;
          max-height: 22.875rem;
          min-height: 20.313rem;
          height: fit-content;

          width: 12.5rem !important;
          padding: 0 !important;
          overflow: hidden !important;
          min-width: 12.5rem !important;
          @include media-breakpoint-up(md) {
            overflow: hidden !important;
            width: auto !important;
            min-width: unset !important;
          }
        }
      }
    }
  }
}

// (oleg): i guess whole page markup needs to be re-done, but it's over my css skills
// to do that
.hotel-details-map {
  google-map {
    height: 480px !important;
  }
}

/* Pro Forma PDF invoice template */
canvas:not(google-map canvas) {
  display: none;
}

/* Bootstrap dropdown override */
.dropdown-menu {
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba($color-black, 0.1);
  border: solid 1px var(--color-grey-light);
  color: var(--color-grey-darker);

  li {
    &:hover,
    &:focus,
    &:active > a {
      background-color: var(--color-grey-light);
      outline: none;
    }
  }
}

.invalid-feedback {
  color: var(--color-red);
  font-family: var(--font-regular);
  line-height: 1.33;
  margin-top: 4px;

  @include set-font-size($font-xs);
}

.collapse-arrow {
  &::after {
    content: '\61';
    display: inline-block;
    font-family: 'roomex';
    vertical-align: middle;
    margin-left: 0.25rem;

    @include transition(transform 0.4s ease);
  }

  &.rotate {
    &::after {
      @include rotate(180deg);
    }
  }
}

/* Custom ngbModal generic styles & overrides */
.modal {
  &-header {
    border-bottom: none;
    padding: 1.5rem 1.5rem 1rem;
  }

  &-body {
    padding: 1.5rem;
  }

  &-footer {
    padding: 1.5rem 0;
    margin: 0 1.5rem;
  }
}

/* Removing double fade effect from ngbModal(s) */
.modal-backdrop.fade {
  transition: none;
}

.modal.modal-xl {
  max-width: 100% !important;
  overflow: hidden;
}

/** Reports - Compliance splash modal */
.modal.compliance-splash-modal {
  .modal-content {
    background: none;
    border-radius: 0;
    border: 0;
  }
}

/* Search summary custom modal that slides in from top on the search results page */
.modal.edit-search-modal {
  animation: slideInFromTop 0.3s;

  .modal-dialog {
    position: fixed;
    margin: 0;
    min-width: 100%;
    padding: 0;

    .modal-content {
      position: absolute;
      width: 100%;
      pointer-events: auto;
      background-color: $color-white;
      background-clip: padding-box;
      border-radius: 0;
      border: 0;
      outline: 0;

      .modal-header,
      .modal-body {
        padding-left: 0;
        padding-right: 0;

        ::ng-deep button.primary {
          padding-right: 12px;
          padding-left: 12px;
        }
      }

      @include media-breakpoint-up(xl) {
        .modal-header .close {
          margin-right: -90px;
        }
      }
    }
  }
}

// todo: Might move this to keyframes component lib
@keyframes slideInFromTop {
  from {
    top: -300px;
    opacity: 0;
  }

  to {
    top: 0;
    opacity: 1;
  }
}

/* IE10+ specific style hacks go here */
@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  #outOfPolicyForm .col,
  .error-page .col-md-6 {
    flex: 0 0 auto;
  }

  .table .ui-sortable-column a .pi-sort {
    display: block !important;

    &::after,
    &::before {
      display: block;
    }
  }

  .list-group-rooms .list-group-item .d-inline-flex {
    display: inline-block !important;
  }
}

.card-shadow {
  box-shadow: 0px 0px 8px 0px rgba($color-black, 0.08);
  padding: 1rem;
  border-radius: 8px;
}

.search-autocomplete-overlay {
  max-height: 65% !important;
}

//fix IOS modal on login page
ngb-modal-backdrop {
  z-index: 1050 !important;
}

//fix all other modals.
::ng-deep body > ngb-modal-backdrop,
::ng-deep body > ngb-modal-window {
  z-index: 1050 !important;
}

.color-red {
  color: var(--color-red);
}

.gm-style-iw.gm-style-iw-c {
  @include media-breakpoint-up(sm) {
    width: auto;
  }
  width: 12.5rem;
  padding: 0;

  & .gm-style-iw-d {
    @include media-breakpoint-up(sm) {
      width: auto;
    }
    width: 12.5rem;
    overflow: auto !important;
  }
}
