/* COLORS */

$color-teal-darker: #005a70;
$color-teal-dark: #008793;
$color-teal: #00b7b1;
$color-teal-light: #83d4d0;
$color-teal-lighter: #dbf5f4;

$color-blue-darker: #001a72;
$color-blue-dark: #1545a1;
$color-blue: #3977c1;
$color-blue-light: #88aeda;
$color-blue-lighter: #d7e4f3;

$color-plum-darker: #523178;
$color-plum-dark: #7f409f;
$color-plum: #ac4fc6;
$color-plum-light: #cd96dd;
$color-plum-lighter: #eedcf4;

$color-grey-darker: #434a51;
$color-grey-dark: #72777d;
$color-grey: #a4a8ab;
$color-grey-light: #e2e3e4;
$color-grey-lighter: #f9f9f9;

$color-green-darker: #2d6c30;
$color-green-dark: #3c9040;
$color-green: #4bb450;
$color-green-light: #93d296;
$color-green-lighter: #dbf0dc;

$color-red-darker: #7f1c1c;
$color-red-dark: #a92626;
$color-red: #d32f2f;
$color-red-light: #e78d8d;
$color-red-lighter: #fbeaea;

$color-yellow-darker: #b0861f;
$color-yellow-dark: #d6a326;
$color-yellow: #fbc02d;
$color-yellow-light: #fddd8c;
$color-yellow-lighter: #faf4e5;

$color-orange: #fb8c00;

$color-black: #000000;
$color-white: #ffffff;

//--------------CORPAY COLOURS------------

$color-corpay-red-main-darker: #981239;
$color-corpay-red-main-dark: #be1549;
$color-corpay-red-main: #E7004C;
$color-corpay-red-main-light: #f8e5ea;
$color-corpay-red-main-lighter: #fbf4f6;

// Green
$color-corpay-green-darker: #125D3A;
$color-corpay-green-dark: #198754;
$color-corpay-green: #5EC07F;
$color-corpay-green-light: #A3CFBB;
$color-corpay-green-lighter: #E8F3EE;

// Red
$color-corpay-red-darker: #98113a;
$color-corpay-red-dark: #be1549;
$color-corpay-red: #DC3545;
$color-corpay-red-light: #EFC4D1;
$color-corpay-red-lighter: #FBF4F6;

// Orange
$color-corpay-orange: #FD7E14;


//Gray
$color-corpay-grey-darker: #444444;
$color-corpay-grey-dark: #737373;
$color-corpay-grey: #A0A0A0;
$color-corpay-grey-light: #e6e8e7;
$color-corpay-grey-lighter: #F8F9FA;

//Yellow
$color-corpay-yellow-darker: #BF8500;
$color-corpay-yellow-dark: #FFB100;
$color-corpay-yellow: #FFCC00;
$color-corpay-yellow-light: #FFEBBF;
$color-corpay-yellow-lighter: #FFF7E5;

//Plum

$color-corpay-plum-darker: #533291;
$color-corpay-plum-dark: #6F42C1;
$color-corpay-plum: #9371D1;
$color-corpay-plum-light: #DBD0EF;
$color-corpay-plum-lighter: #F1ECF9;

//Blue

$color-corpay-blue-darker: #1F7BA3;
$color-corpay-blue-dark: #00B5E2;
$color-corpay-blue: #59CBE8;
$color-corpay-blue-light: #C2E9F1;
$color-corpay-blue-lighter: #E6F6F9;


/* FONTS */

// Font Variations (Font Family: Proxima Nova)
$font-regular: 'proxima-regular';
$font-semibold: 'proxima-semibold';
$font-bold: 'proxima-bold';

// Font size base for calculating REM units
$font-base: 16px !default;

// Font sizes and line heights
$font-xs: 12px;
$font-xs-lh: 18px;

$font-sm: 14px;
$font-sm-lh: 22px;

$font-md: 16px;
$font-md-lh: 24px;

$font-lg: 18px;
$font-lg-lh: 28px;

$font-xl: 22px;
$font-xl-lh: 33px;

//COPRAY TYPOGRAPHY

$font-corpay-regular: 'america-regular';
$font-corpay-semibold: 'america-semibold';
$font-corpay-bold: 'america-bold';
