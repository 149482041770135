@use '../variables' as vars;

@mixin roomex-theme-variables {
  --color-primary-darker: #{vars.$color-teal-darker};
  --color-primary-dark: #{vars.$color-teal-dark};
  --color-primary: #{vars.$color-teal};
  --color-primary-light: #{vars.$color-teal-light};
  --color-primary-lighter: #{vars.$color-teal-lighter};

  --color-orange: #{vars.$color-orange};

  --color-grey-darker: #{vars.$color-grey-darker};
  --color-grey-dark: #{vars.$color-grey-dark};
  --color-grey: #{vars.$color-grey};
  --color-grey-light: #{vars.$color-grey-light};
  --color-grey-lighter: #{vars.$color-grey-lighter};

  --color-green-darker: #{vars.$color-green-darker};
  --color-green-dark: #{vars.$color-green-dark};
  --color-green: #{vars.$color-green};
  --color-green-light: #{vars.$color-green-light};
  --color-green-lighter: #{vars.$color-green-lighter};

  --color-red-darker: #{vars.$color-red-darker};
  --color-red-dark: #{vars.$color-red-dark};
  --color-red: #{vars.$color-red};
  --color-red-light: #{vars.$color-red-light};
  --color-red-lighter: #{vars.$color-red-lighter};

  --color-yellow-darker: #{vars.$color-yellow-darker};
  --color-yellow-dark: #{vars.$color-yellow-dark};
  --color-yellow: #{vars.$color-yellow};
  --color-yellow-light: #{vars.$color-yellow-light};
  --color-yellow-lighter: #{vars.$color-yellow-lighter};

  --color-plum-darker: #{vars.$color-plum-darker};
  --color-plum-dark: #{vars.$color-plum-dark};
  --color-plum: #{vars.$color-plum};
  --color-plum-light: #{vars.$color-plum-light};
  --color-plum-lighter: #{vars.$color-plum-lighter};

  --color-blue-darker: #{vars.$color-blue-darker};
  --color-blue-dark: #{vars.$color-blue-dark};
  --color-blue: #{vars.$color-blue};
  --color-blue-light: #{vars.$color-blue-light};
  --color-blue-lighter: #{vars.$color-blue-lighter};

  --font-regular: #{vars.$font-regular};
  --font-semibold: #{vars.$font-semibold};
  --font-bold: #{vars.$font-bold};

  --button-background-hover: #{vars.$color-teal-darker};
  --input-active-stroke: #{vars.$color-teal};
}
