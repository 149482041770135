// Function and Mixin from converting px to rem
@use "sass:math";

@function calculate-rem($size) {
    $remSize: math.div($size, 16px);
    @return #{$remSize}rem;
}

/*
 * Calculating REM units for font size and line height
 * The first argument is for the font-size in px.
 * The second argument is for line-height in px and is optional.
 */
@mixin set-font-size($size, $line-height: 0) {
    font-size: $size;
    font-size: calculate-rem($size);

    @if ($line-height > 0) {
      line-height: $line-height;
      line-height: math.div($line-height, 16px) * 1rem;
    }
}

// Mixin for Transition
@mixin transition($value...) {
    -webkit-transition: $value;
    -moz-transition: $value;
    -ms-transition: $value;
    -o-transition: $value;
    transition: $value;
}

// Mixin for Transform
@mixin transform($value...) {
	-webkit-transform: $value;
	-moz-transform: $value;
	-ms-transform: $value;
	-o-transform: $value;
	transform: $value;
}

@mixin transform-origin($value...) {
	-webkit-transform-origin: $value;
	-moz-transform-origin: $value;
	transform-origin: $value;
}

// Mixin for Center any element X and Y axis
@mixin center-xy {
	position: absolute;
	left: 50%;
	top: 50%;
	@include transform(translate(-50%,-50%));
}

// Mixin for Placeholder color
@mixin placeholder-color($value...) {
	&::-webkit-input-placeholder {
		color: $value;
		opacity: 1;
	}
	&::-moz-placeholder {
		color: $value;
		opacity: 1;
	}
	&:-ms-input-placeholder {
		color: $value;
		opacity: 1;
	}
	&:-moz-placeholder {
		color: $value;
		opacity: 1;
	}
}

@mixin ellipsis-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin font-smoothing-antialiased {
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin rotate($degrees) {
  -webkit-transform: rotate($degrees);
  -moz-transform: rotate($degrees);
  -ms-transform: rotate($degrees);
  -o-transform: rotate($degrees);
  transform: rotate($degrees);
}

@mixin fade-in ($duration) {
  display: block;
  animation-name: fadeInOpacity;
  animation-fill-mode: both;
  animation-duration: $duration;
}

@mixin fade-out ($duration) {
  display: none;
  animation-name: fadeOutOpacity;
  animation-fill-mode: both;
  animation-duration: $duration;
}

@mixin overlay($color, $opacity) {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba($color, $opacity);
}

@keyframes fadeOutOpacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
