/*
  To use the media-breakpoint-* mixins, Bootstrap imports the whole _variables file which is very large one.
  Thus, by importing this file you can use the same mixins without increasing to much the final bundle size
*/

@import 'bootstrap/scss/functions';

// copied out of 'bootstrap/scss/_variables';
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");

@import 'bootstrap/scss/mixins/breakpoints';
