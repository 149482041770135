@charset "UTF-8";

// 5. themes
@forward "libs/shared/rx-components/styles/themes/roomex";
@forward "libs/shared/rx-components/styles/themes/corpaylodging";

// 1. Configuration and helpers VARIABLES
@import 'abstracts';

$font-family-base: var(--font-regular);

// 2. Vendors STYLES - CREDIT: https://sass-guidelin.es/#architecture
@import 'bootstrap/scss/bootstrap';

// 3. Component lib STYLES and VARIABLES
@import 'libs/shared/rx-components/styles/icons';
@import 'libs/shared/rx-components/styles/fonts';
@import 'libs/shared/rx-components/styles/base';
@import 'libs/shared/rx-components/styles/typography';

// 4. Shame file
@import 'shame';


