/*
  All font related styles go here
  Using REM to calculate relative size to the root (body) element font size which by default equals to 16px
*/

body {
  @include set-font-size($font-base);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-bold);
  color: var(--color-grey-darker);
}

h1 {
  @include set-font-size(40px, 50px);
}

h2 {
  @include set-font-size(32px, 40px);
}

h3 {
  @include set-font-size(24px, 32px);
}

h4 {
  @include set-font-size(18px, 28px);
}

h5 {
  @include set-font-size(16px, 24px);
}

h6 {
  @include set-font-size(14px, 22px);
}

p {
  font-family: var(--font-regular);

  @include set-font-size($font-md, $font-md-lh);
}

a {
  font-family: var(--font-regular);
}

form label {
  font-family: var(--font-semibold);

  @include set-font-size($font-sm, $font-sm-lh);
}

.f-size {
  &-xl {
    @include set-font-size($font-xl, $font-xl-lh);
  }
  &-lg {
    @include set-font-size($font-lg, $font-lg-lh);
  }
  &-md {
    @include set-font-size($font-md, $font-md-lh);
  }
  &-sm {
    @include set-font-size($font-sm, $font-sm-lh);
  }
  &-xs {
    @include set-font-size($font-xs, $font-xs-lh);
  }
}

.f-family {
  &-regular {
    font-family: var(--font-regular);
  }
  &-semibold {
    font-family: var(--font-semibold);
  }
  &-bold {
    font-family: var(--font-bold);
  }
}

.f-color {
  &-regular {
    color: var(--color-grey-darker);
  }
  &-light {
    color: var(--color-grey-dark);
  }
  &-lighter {
    color: var(--color-grey);
  }
}
